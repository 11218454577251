import React from "react"
import Head from "next/head"

const Index = () => {
  return (
    <>
      <Head>
        <title>
          Omie : + de 100 produits sains, pleins de goût et radicalement
          transparents
        </title>
      </Head>
      <h1>
        Omie : + de 100 produits sains, pleins de goût et radicalement
        transparents
      </h1>
    </>
  )
}

export default Index
